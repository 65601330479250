import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  checkout() {
    Paddle.Checkout.open({
      product:       this.data.get("product"),
      passthrough:   this.data.get("passthrough"),
      email:         this.data.get("email"),
      success:       this.data.get("success"),
      disableLogout: true,
    })
  }

  page() {
    Paddle.Checkout.open({
      override: this.data.get("url")
    })
  }
}
