import { StreamActions } from "@hotwired/turbo"

StreamActions.reload_frame = function () {
  const streamVersion = parseInt(this.getAttribute("reload_version"))
  if (!streamVersion) return

  this.targetElements.forEach((frameElement) => {
    // Ignore frames with non-finished request.
    if (frameElement.hasAttribute("busy")) return

    // Meta element must be the first child of the frame element.
    // It contains metadata such as version and reload src.
    const metaElement = frameElement.children[0]
    if (!metaElement) return

    // Check if meta element is carrying the required data.
    const frameMetaSrc = metaElement.dataset.turboReloadSrc
    const frameMetaVersion = parseInt(metaElement.dataset.turboReloadVersion)
    if (!frameMetaSrc || !frameMetaVersion) return

    // Ignore frames with a newer version.
    if (frameMetaVersion >= streamVersion) return

    // Frame src is changed before the busy attribute is set.
    // So, ignore also frames with changed src and with old content.
    // We are using includes because the frame src is an absolute URL.
    if (frameElement.src && !frameElement.src.includes(frameMetaSrc)) return

    // Finally reload the frame
    frameElement.src = frameMetaSrc
  })
}
