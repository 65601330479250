import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["roleSelect", "languages", "availableLanguage", "editableLanguage"]

  connect() {
    this.updateLanguagesVisibility()
  }

  updateLanguagesVisibility() {
    const isTranslator = this.roleSelectTarget.value === "translator"
    this.languagesTarget.hidden = !isTranslator
  }

  updateEditableLanguages(event) {
    const changedLanguage = event.target
    if (!changedLanguage.checked) {
      this.editableLanguageTargets.forEach(editableLanguage => {
        if (editableLanguage.value === changedLanguage.value) {
          editableLanguage.checked = false
        }
      })
    }
  }

  updateAvailableLanguages(event) {
    const changedLanguage = event.target
    if (changedLanguage.checked) {
      this.availableLanguageTargets.forEach(availableLanguage => {
        if (availableLanguage.value === changedLanguage.value) {
          availableLanguage.checked = true
        }
      })
    }
  }
}
