import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    targetId: String,
    targetType: String
  }

  connect() {
    const interval = 10000

    const csrfTokenTag = document.querySelector('meta[name="csrf-token"]')
    const csrfToken = csrfTokenTag ? csrfTokenTag.getAttribute("content") : null

    this.pulseTimer = setInterval(() => this._pulse(csrfToken), interval)
  }

  disconnect() {
    clearTimeout(this.pulseTimer)
  }

  _pulse(csrfToken) {
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      body: JSON.stringify({
        edit_lock: {
          target_id: this.targetIdValue,
          target_type: this.targetTypeValue
        }
      })
    })
  }
}
