import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addArrayFieldBtn", "arrayField", "arrayFieldTemplate", "submitBtn", "cancelBtn"]

  removeArrayField(event) {
    event.preventDefault()
    this.arrayFieldTargets.forEach(arrayFieldTarget => {
      if (arrayFieldTarget.contains(event.target)) {
        arrayFieldTarget.remove()
      }
    })
  }

  addArrayField(event) {
    event.preventDefault()
    this.addArrayFieldBtnTarget.insertAdjacentHTML("beforebegin",
      this.arrayFieldTemplateTarget.innerHTML
    )
  }

  submitForm() {
    this.submitBtnTarget.click()
  }

  cancelForm() {
    this.cancelBtnTarget.click()
  }
}
