import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "label"]

  connect() {
    this.updateFilter()
  }

  updateFilter() {
    const checkedLanguagesCnt = this.checkboxTargets.filter(el => el.checked).length
    const labelBase = this.data.get("label")
    this.labelTarget.innerHTML = `${labelBase} (${checkedLanguagesCnt})`
  }
}
