import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.updateBound = this._update.bind(this)
    this.pageResizeThrottledBound = this._pageResizeThrottled.bind(this)
  }

  connect() {
    this.timer = null
    this.clientWidth = null

    this.element.style.resize = "none"
    this.element.style.overflow = "hidden"
    this.element.style.overflowWrap = "break-word"

    this.minHeight = this.element.offsetHeight

    this.element.addEventListener("input", this.updateBound)
    window.addEventListener("resize", this.pageResizeThrottledBound)

    this._update()
  }

  disconnect() {
    clearTimeout(this.timer)

    this.element.removeEventListener("input", this.updateBound)
    window.removeEventListener("resize", this.pageResizeThrottledBound)
  }

  _update() {
    if (this.element.scrollHeight === 0) {
      return
    }

    this.element.style.height = "auto"

    const height = Math.max(this.minHeight, this.element.scrollHeight)

    let offset = 0
    const computedStyle = window.getComputedStyle(this.element)
    if (this.element.style.boxSizing === "content-box") {
      offset -= parseInt(computedStyle.paddingTop)
      offset -= parseInt(computedStyle.paddingBottom)
    } else {
      offset += parseInt(computedStyle.borderTopWidth)
      offset += parseInt(computedStyle.borderBottomWidth)
    }

    this.element.style.height = `${height + offset}px`

    this.clientWidth = this.element.clientWidth
  }

  _pageResize() {
    if (this.element.clientWidth !== this.clientWidth) {
      this._update()
    }
  }

  _pageResizeThrottled() {
    clearTimeout(this.timer)
    this.timer = setTimeout(this._pageResize.bind(this), 100)
  }
}
