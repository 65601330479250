import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["toggler", "menu"]
  static classes = ["menuInactive"]

  connect() {
    this.popper = null
  }

  disconnect() {
    this.forceHide()
  }

  toggle() {
    this._destroyPopper()

    if (!this.menuTarget.classList.toggle(this.menuInactiveClass)) {
      this.popper = createPopper(this.togglerTarget, this.menuTarget, {
        placement: this.data.get("placement"),
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, 6] },
          },
        ],
      })
    }
  }

  hide(event) {
    if (this.element.contains(event.target) === false) {
      this.forceHide()
    }
  }

  forceHide() {
    this._destroyPopper()
    this.menuTarget.classList.add(this.menuInactiveClass)
  }

  _destroyPopper() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  }
}
